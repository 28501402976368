import UseTranslation from "next-translate/useTranslation";
import React, { useEffect, useState, useState as UseState } from "react";
import styles from "./styles.module.css";
import { Get, PostArrayData, Post } from "../../../../libs/api/rest";
import { IsNullOrEmpty } from "../../../../libs/utils";
import { LanguageUid } from "../../../../libs/enums";

var _error = {};

const DeliveryTimes = (props) => {
  const [domesticType, setDomesticType] = UseState("domestic");
  const [shipmentType, setShipmentType] = UseState("Kargo");
  const [cityList, SetCityList] = useState([{ kod: "", il_ad: "" }]);
  const [countryList, SetCountryList] = useState([
    { ulke_kod: "", ulke_ad: "" },
  ]);
  const { t } = UseTranslation("GetLanguageResourceResponse");
  const [form, setForm] = useState({
    cikisIl: "",
    varisIl: "",
    sorguTip: "Kargo",
  });
  const [abroadForm, setAbroadForm] = useState({
    apg: 0,
    koli: 1,
    turpex: 0,
    countryCode: "",
    transport: 1,
  });
  const [search, setSearch] = UseState(false);
  const [deliveryTimes, setDeliveryTimes] = UseState();
  const [deliveryTimesAbroad, setDeliveryTimesAbroad] = UseState();
  const [filterExitCity, setFilterExitCity] = UseState("");
  const [filterDestinationCity, setFilterDestinationCity] = UseState("");
  const [filterCountry, setFilterCountry] = UseState("");
  const [selectExitCity, setSelectExitCity] = UseState(false);
  const [selectDestinationCity, setSelectDestinationCity] = UseState(false);
  const [selectCountry, setSelectCountry] = UseState(false);

  const onSetDomesticType = (e) => {
    setDomesticType(e.target.value);
  };

  const onSetShipmentType = (e) => {
    setShipmentType(e.target.value);
    setForm({ ...form, sorguTip: e.target.value });
  };
  const onSetAbroadTransportType = (e) => {
    setAbroadForm({ ...abroadForm, transport: e.target.value });
  };

  const onGetDeliveryTimes = async () => {
    if (IsNullOrEmpty(form)) return;
    _error.Message = "";

    await PostArrayData(
      `${process.env.NEXT_PUBLIC_MAINAPI_URL}/DeliveryTimes`,
      form
    ).then((result) => {
      try {
        if (result.state) {
          if (result.hata_aciklama === "KAYIT YOK") {
            _error.Message = result[0].hata_aciklama;
            return;
          }
          setDeliveryTimes(result);
          _error.Message = "";
        } else {
          _error.Message = result.hata_aciklama;
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const onGetDeliveryTimesAbroad = async () => {
    if (IsNullOrEmpty(abroadForm)) return;
    await PostArrayData(
      `${process.env.NEXT_PUBLIC_MAINAPI_URL}/DeliveryTimes/getDeliveryTimesAbroad`,
      abroadForm
    ).then((result) => {
      setDeliveryTimesAbroad(result);
    });
  };

  const filteredDestinationCity = cityList.filter((item) => {
    return Object.keys(item).some((newItem) => {
      return item[newItem]
        .toString().replaceAll("I","ı")
        .toLocaleLowerCase()
        .includes(filterDestinationCity.toLocaleLowerCase());
    });
  });

  const filteredExitCity = cityList.filter((item) => {
    return Object.keys(item).some((newItem) => {
      return item[newItem]
        .toString().replaceAll("I","ı")
        .toLocaleLowerCase()
        .includes(filterExitCity.toLocaleLowerCase());
    });
  });

  const filteredCountry = countryList.filter((item) => {
    return Object.keys(item).some((newItem) => {
      return item[newItem]
        .toString()
        .toLowerCase()
        .includes(filterCountry.toLowerCase());
    });
  });

  useEffect(() => {
    (async () => {
      await Get(
        `${process.env.NEXT_PUBLIC_MAINAPI_URL}/DeliveryTimes/getCities`
      ).then((result) => {
        SetCityList(result);
      });
      await Get(
        `${process.env.NEXT_PUBLIC_MAINAPI_URL}/DeliveryTimes/getCountries`
      ).then((result) => {
        SetCountryList(result);
      });
    })();
  }, []);

  return (
    <>
      <div className={`row ${styles.buttonGroup} `}>
        <label className={styles.radioButton} htmlFor="domestic">
          <input
            id="domestic"
            type="radio"
            name="selectOne"
            value="domestic"
            checked={domesticType === "domestic"}
            onChange={(event) => {
              onSetDomesticType(event);
            }}
          />
          <span>
            <i className="fa-solid fa-check"></i>
          </span>
          {t(LanguageUid.DT_DOMESTIC)}
        </label>

        <label className={styles.radioButton} htmlFor="abroad">
          <input
            id="abroad"
            type="radio"
            name="selectOne"
            value="abroad"
            checked={domesticType === "abroad"}
            onChange={(event) => {
              onSetDomesticType(event);
            }}
          />
          <span>
            <i className="fa-solid fa-check"></i>
          </span>
          {t(LanguageUid.DT_ABROAD)}
        </label>
      </div>

      {domesticType === "domestic" ? (
        <>
          {/* Start [Kargo, Aps, Mektup] */}
          <div className={`row ${styles.buttonGroup} `}>
            <label className={styles.radioButton} htmlFor="cargo">
              <input
                id="cargo"
                type="radio"
                name="selectTwo"
                value="Kargo"
                checked={shipmentType === "Kargo"}
                onChange={(event) => {
                  onSetShipmentType(event);
                }}
              />
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              {t(LanguageUid.DT_CARGO)}
            </label>

            <label className={styles.radioButton} htmlFor="aps">
              <input
                id="aps"
                type="radio"
                name="selectTwo"
                value="APG"
                onChange={(event) => {
                  onSetShipmentType(event);
                }}
              />
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              {t(LanguageUid.DT_APS)}
            </label>

            <label className={styles.radioButton} htmlFor="letter">
              <input
                id="letter"
                type="radio"
                name="selectTwo"
                value="Mektup"
                onChange={(event) => {
                  onSetShipmentType(event);
                }}
              />
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              {t(LanguageUid.DT_LETTER)}
            </label>
          </div>
          {/* End [Kargo, Aps, Mektup] */}

          {/* Start Çıkış ve Varış İl */}
          <div className="row">
            <div className="col-5 col-t-5 position-relative">
              <input
                name="exitCity"
                className={styles.iExitProvince}
                placeholder={t(LanguageUid.DT_FROM)}
                value={filterExitCity ?? ""}
                autoComplete={"off"}
                onChange={(e) => {
                  setFilterExitCity(e.target.value);
                  setSelectExitCity(false);
                }}
              />

              {filterExitCity && !selectExitCity && (
                <ul className={styles.cityList}>
                  {filteredExitCity &&
                    filteredExitCity.map((exitCity, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setForm({
                            ...form,
                            cikisIl: exitCity.kod?.toString(),
                          });
                          setFilterExitCity(exitCity.il_ad);
                          setSelectExitCity(true);
                        }}
                      >
                        {exitCity.il_ad}
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div className="col-5 col-t-5 position-relative">
              <input
                name="DestinationCity"
                className={styles.iArrivalProvince}
                placeholder={t(LanguageUid.DT_TO)}
                value={filterDestinationCity ?? ""}
                autoComplete={"off"}
                onChange={(e) => {
                  setFilterDestinationCity(e.target.value);
                  setSelectDestinationCity(false);
                }}
              />

              {filterDestinationCity && !selectDestinationCity && (
                <ul className={styles.cityList}>
                  {filteredDestinationCity &&
                    filteredDestinationCity.map((destinationCity, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setForm({
                            ...form,
                            varisIl: destinationCity.kod?.toString(),
                          });
                          setFilterDestinationCity(destinationCity.il_ad);
                          setSelectDestinationCity(true);
                        }}
                      >
                        {destinationCity.il_ad}
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div className="col-2 col-t-2  ">
              <button
                className={styles.findButton}
                onClick={async () => {
                  await onGetDeliveryTimes();
                }}
              >
                <i className="fa-solid fa-magnifying-glass-arrow-right"></i>
              </button>
            </div>
          </div>
          {/* End Çıkış ve Varış İl */}

          {deliveryTimes ? (
            <strong className={styles.result}>
              {t(LanguageUid.DT_DELİVERY_TIMES)}
              <i>
                {IsNullOrEmpty(deliveryTimes.teslim_sure)
                  ? "..."
                  : deliveryTimes.teslim_sure}
              </i>
              {t(LanguageUid.DT_DAY)}
            </strong>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {/* Start [Koli, Mektup, Apg,Turpex] */}
          <div className={`row ${styles.buttonGroup} `}>
            <label className={styles.radioButton} htmlFor="package">
              <input
                id="package"
                type="radio"
                name="selectThree"
                value="Koli"
                onChange={(event) => {
                  setAbroadForm({
                    ...abroadForm,
                    koli: 1,
                    turpex: 0,
                    apg: 0,
                  });
                }}
              />
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              {t(LanguageUid.DT_PACKAGE)}
            </label>

            <label className={styles.radioButton} htmlFor="letterAbroad">
              <input
                id="letterAbroad"
                type="radio"
                name="selectThree"
                value="Mektup"
                onChange={(event) => {
                  setAbroadForm({
                    ...abroadForm,
                    koli: 1,
                    turpex: 0,
                    apg: 0,
                  });
                }}
              />
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              {t(LanguageUid.DT_LETTER)}
            </label>

            <label className={styles.radioButton} htmlFor="apg">
              <input
                id="apg"
                type="radio"
                name="selectThree"
                value="APG"
                onChange={() => {
                  setAbroadForm({
                    ...abroadForm,
                    koli: 0,
                    turpex: 0,
                    apg: 1,
                  });
                }}
              />
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              {t(LanguageUid.DT_APG)}
            </label>

            <label className={styles.radioButton} htmlFor="turpex">
              <input
                id="turpex"
                type="radio"
                name="selectThree"
                value="Turpex"
                onChange={() => {
                  setAbroadForm({
                    ...abroadForm,
                    koli: 0,
                    turpex: 1,
                    apg: 0,
                  });
                }}
              />
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              {t(LanguageUid.DT_TURPEX)}
            </label>
          </div>
          {/* End [Koli, Mektup, Apg,Turpex] */}

          {/* Start [surface,plane ] */}
          <div className={`row ${styles.buttonGroup} `}>
            {abroadForm.apg !== 1 && abroadForm.turpex !== 1 ? (
              <label className={styles.radioButton} htmlFor="surface">
                <input
                  id="surface"
                  type="radio"
                  name="selectFour"
                  checked={abroadForm.transport == "1"}
                  value="1"
                  onChange={(event) => {
                    onSetAbroadTransportType(event);
                    onGetDeliveryTimesAbroad();
                  }}
                />
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
                {t(LanguageUid.DT_SURFACE)}
              </label>
            ) : (
              ""
            )}

            <label className={styles.radioButton} htmlFor="plane">
              <input
                id="plane"
                type="radio"
                name="selectFour"
                value="2"
                onChange={(event) => {
                  onSetAbroadTransportType(event);
                }}
              />
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
              {t(LanguageUid.DT_PLANE)}
            </label>
          </div>
          {/* End [surface,plane ] */}

          {/* Start [country] */}
          <div className="row">
            <div className="col-10 col-t-10">
              <input
                name="country"
                className={styles.iExitProvince}
                placeholder={t(LanguageUid.DELIVERY_TIME_PLACEHOLDER)}
                value={filterCountry ?? ""}
                autoComplete={"off"}
                onChange={(e) => {
                  setFilterCountry(e.target.value);
                }}
              />

              {filterCountry && !selectCountry && (
                <ul className={styles.cityList}>
                  {filteredCountry &&
                    filteredCountry.map((country, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setAbroadForm({
                            ...abroadForm,
                            countryCode: country.ulke_kod,
                          });
                          setFilterCountry(country.ulke_ad);
                          setSelectCountry(true);
                        }}
                      >
                        {country.ulke_ad}
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div className="col-2 col-t-2">
              <button
                className={styles.findButton}
                onClick={async () => {
                  await onGetDeliveryTimesAbroad();
                }}
              >
                <i className="fa-solid fa-magnifying-glass-arrow-right"></i>
              </button>
            </div>
          </div>
          {/* End [country] */}

          {deliveryTimesAbroad ? (
            <strong className={styles.result}>
              {t(LanguageUid.DT_DELİVERY_TIMES)}
              <i>
                {IsNullOrEmpty(deliveryTimesAbroad.value.message)
                  ? "..."
                  : deliveryTimesAbroad.value.message}
              </i>
              {t(LanguageUid.DT_DAY)}
            </strong>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default DeliveryTimes;
