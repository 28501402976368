import UseTranslation from "next-translate/useTranslation";
import React, { useEffect, useState } from "react";
import { Get, PostArrayData } from "../../../../libs/api/rest";
import { IsNullOrEmpty } from "../../../../libs/utils";
import styles from "./styles.module.css";
import { LanguageUid } from "../../../../libs/enums";
import Link from 'next/link'

const DeliveryFee = (props) => {
  const [deliveryFee, setDeliveryFee] = useState("");
  const [abrDeliveryFee, setabrDeliveryFee] = useState({
    apg: "",
    koli: "",
    turpex: "",
    taahhutlu: "",
  });

  const [domesticType, setDomesticType] = useState("domestic");
  const { t } = UseTranslation("GetLanguageResourceResponse");
  const [countryList, setCountryList] = useState([
    { ulke_kod: "", ulke_ad: "" },
  ]);
  const [filterCountryList, setFilterCountryList] = useState("");
  const [selectCountryList, setSelectCountryList] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  const [screenWidth, setScreenWidth] = useState(0);

  const [abrform, setAbrForm] = useState({
    ulkeKod: "",
    agirlik: "",
    en: "",
    boy: "",
    yukseklik: "",
  });

  const [search, setSearch] = useState(false);
  var _error = {};
  const onSetDomesticType = (e) => {
    setDomesticType(e.target.value);
  };

  const onChangeRadio = (event) => {
    setAbrForm({
      ulkeKod: "",
      agirlik: "",
      en: "",
      boy: "",
      yukseklik: "",
    });

    setabrDeliveryFee({ apg: "", koli: "", turpex: "", taahhutlu: "" });

    setDeliveryFee("");
  };

  useEffect(() => {
    (async () => {
      await Get(
        `${process.env.NEXT_PUBLIC_MAINAPI_URL}/DeliveryFee/getCountries`
      ).then((result) => {
        setCountryList(result);
      });
    })();

    setScreenWidth(window.screen.width);
  }, []);

  const filteredCountryList = countryList.filter((item) => {
    return Object.keys(item).some((newItem) => {
      return item[newItem]
        .toString()
        .toLowerCase()
        .includes(filterCountryList.toLowerCase());
    });
  });

  const onGetAbroadDeliveryFee = async () => {
    _error.Message = "";
    setErrorVisible(false);
    setSearch(true);

    if (
      !abrform ||
      Object.keys(abrform).length === 0 ||
      abrform.ulkeKod === "" ||
      abrform.agirlik === "" ||
      abrform.agirlik === undefined
    ) {
      _error.Message = "Geçersiz veya içinde boş değerler var.";
      setErrorVisible(true);
      return <ReturnError />;
    }

    const ydParams = {
      countryCode: abrform.ulkeKod,
      weight: abrform.agirlik,
    };

    await PostArrayData(
      `${process.env.NEXT_PUBLIC_MAINAPI_URL}/DeliveryFee/getAbroad`,
      ydParams
    ).then((result) => {
      try {
        if (!IsNullOrEmpty(result)) {
          setabrDeliveryFee({
            apg: result["YD APG"].fee,
            koli: result["YD KOLİ"].fee,
            turpex: result["TURPEX_PTT"].fee,
            taahhutlu: result["MEKTUP"].fee,
          });

          setSearch(true);
        } else {
          _error.Message = "Sonuç Yok";
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const onGetDeliveryFee = async () => {
    setSearch(true);
    setErrorVisible(false);
    if (
      !abrform ||
      Object.keys(abrform).length === 0 ||
      abrform.agirlik === "" ||
      abrform.yukseklik === "" ||
      abrform.en === "" ||
      abrform.boy === ""
    ) {
      _error.Message = "Geçersiz veya içinde boş değerler var.";
      setErrorVisible(true);
      return <ReturnError />;
    }
    const yIParams = {
      agirlik: abrform.agirlik,
      boy: abrform.boy,
      en: abrform.en,
      yukseklik: abrform.yukseklik,
    };
    await PostArrayData(
      `${process.env.NEXT_PUBLIC_MAINAPI_URL}/DeliveryFee`,
      yIParams
    ).then((result) => {
      try {
        if (!IsNullOrEmpty(result.ucret)) {
          setDeliveryFee(result.ucret);
          console.log(result.ucret);
          setSearch(false);
        } else {
          _error.Message = "Sonuç Yok";
        }
      } catch (error) {
        console.error(error);
      }
    });
  };
  const ReturnError = () => {
    return (
      <>
        {" "}
        <span title={t(LanguageUid.DF_POST_ERROR)}>
          <p className={styles.formLink}>{t(LanguageUid.DF_POST_ERROR)}</p>
        </span>
      </>
    );
  };
  return (
    <>
      <div className={`row ${styles.buttonGroup} `}>
        <label className={styles.radioButton} htmlFor="domestic">
          <input
            id="domestic"
            type="radio"
            value="domestic"
            checked={domesticType === "domestic"}
            onChange={(event) => {
              onSetDomesticType(event);
              onChangeRadio(event);
            }}
          />
          <span>
            <i className="fa-solid fa-check"></i>
          </span>
          {t(LanguageUid.DT_DOMESTIC)}
        </label>

        <label className={styles.radioButton} htmlFor="abroad">
          <input
            id="abroad"
            type="radio"
            value="abroad"
            checked={domesticType === "abroad"}
            onChange={(event) => {
              onSetDomesticType(event);
              onChangeRadio(event);
            }}
          />
          <span>
            <i className="fa-solid fa-check"></i>
          </span>
          {t(LanguageUid.DT_ABROAD)}
        </label>
      </div>

      {domesticType === "domestic" ? (
        <>
          <div className="row">
            <div id={styles.domesticrow} className="col-7 col-t-6">
              <div className="row mb-5">
                <div className="col-6 col-t-6">
                  <input
                    type="text"
                    name="agirlik"
                    maxLength={10}
                    className={styles.inputLeft}
                    placeholder={t(LanguageUid.DF_WEIGHT)}
                    value={abrform.agirlik || ""}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const numericValue = inputValue.replace(/[^0-9]/g, "");
                      setAbrForm((prevAbrForm) => ({
                        ...prevAbrForm,
                        agirlik: numericValue,
                      }));
                    }}
                  />
                </div>

                <div className="col-6 col-t-6">
                  <input
                    type="text"
                    name="en"
                    maxLength={10}
                    className={styles.inputRight}
                    placeholder={t(LanguageUid.DF_WIDTH)}
                    value={abrform.en || ""}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const numericValue = inputValue.replace(/[^0-9]/g, "");
                      setAbrForm((prevAbrForm) => ({
                        ...prevAbrForm,
                        en: numericValue,
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-6 col-t-6">
                  <input
                    type="text"
                    name="boy"
                    maxLength={10}
                    className={styles.inputLeft}
                    placeholder={t(LanguageUid.DF_LENGTH)}
                    value={abrform.boy || ""}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const numericValue = inputValue.replace(/[^0-9]/g, "");
                      setAbrForm((prevAbrForm) => ({
                        ...prevAbrForm,
                        boy: numericValue,
                      }));
                    }}
                  />
                </div>

                <div className="col-6 col-t-6">
                  <input
                    type="text"
                    name="yukseklik"
                    maxLength={10}
                    className={styles.inputRight}
                    placeholder={t(LanguageUid.DF_HEIGHT)}
                    value={abrform.yukseklik || ""}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const numericValue = inputValue.replace(/[^0-9]/g, "");
                      setAbrForm((prevAbrForm) => ({
                        ...prevAbrForm,
                        yukseklik: numericValue,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-5 col-t-6 pl-5">
              <fieldset className={styles.result}>
                <legend>{t(LanguageUid.DT_RESULT)}</legend>
                <strong>
                  {IsNullOrEmpty(deliveryFee) ? "..." : deliveryFee}
                </strong>
              </fieldset>
            </div>
            {errorVisible && <ReturnError />}
          </div>

          <div className={styles.feeFooter}>
            <div className="col-t-10 ">
              <Link
                title={t(LanguageUid.DF_DETAIL_POST)}
                href="/gonderi-ucreti-hesaplama"
              >
                <p className={styles.formLink}>
                  {t(LanguageUid.DF_DETAIL_POST)}
                </p>
              </Link>
            </div>
            <div className="col-t-2 ml-6">
              <button
                className={styles.findButton}
                onClick={async () => {
                  await onGetDeliveryFee();
                }}
              >
                <i className="fa-solid fa-magnifying-glass-arrow-right"></i>
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row mb-5 position-relative">
            <input
              name="ulkeKod"
              placeholder={t(LanguageUid.DF_COUNTRY)}
              className={styles.input}
              value={filterCountryList ?? ""}
              autoComplete={"off"}
              onChange={(e) => {
                setFilterCountryList(e.target.value);
                setSelectCountryList(false);
              }}
            />
            {filterCountryList && !selectCountryList && (
              <ul className={styles.list}>
                {filteredCountryList &&
                  filteredCountryList.map((ulke, index) => (
                    <li
                      key={index}
                      onClick={(e) => {
                        setAbrForm({
                          ...abrform,
                          ulkeKod: ulke.ulke_kod?.toString(),
                        });
                        setFilterCountryList(ulke.ulke_ad);
                        setSelectCountryList(true);
                      }}
                    >
                      {ulke.ulke_ad}
                    </li>
                  ))}
              </ul>
            )}
          </div>

          <div className="row align-items">
            <div className="col-7 col-t-6">
              <div className="row mb-5 ">
                <input
                  type="text"
                  name="agirlik"
                  maxLength={10}
                  className={styles.input}
                  placeholder={t(LanguageUid.DF_WEIGHT)}
                  value={abrform.agirlik || ""}
                  onChange={(event) => {
                    const inputValue = event.target.value;

                    // Sadece sayıları içeren bir string elde etmek için regular expression kullanalım
                    const numericValue = inputValue.replace(/[^0-9]/g, "");

                    setAbrForm((prevAbrForm) => ({
                      ...prevAbrForm,
                      agirlik: numericValue,
                    }));
                  }}
                />

                {/* <div className="col-6  col-t-6">
                  <input
                    type={"number"}
                    name="en"
                    className={styles.inputRight}
                    placeholder={t(LanguageUid.DF_WIDTH)}
                    onKeyUp={(event) => {
                      onsetAbrForm(event);
                    }}
                  />
                </div> */}
              </div>

              {/* <div className="row mb-5">
                <div className="col-6  col-t-6">
                  <input
                    type={"number"}
                    name="boy"
                    className={styles.inputLeft}
                    placeholder={t(LanguageUid.DF_LENGTH)}
                    onKeyUp={(event) => {
                      onsetAbrForm(event);
                    }}
                  />
                </div>

                <div className="col-6  col-t-6">
                  <input
                    type={"number"}
                    name="yukseklik"
                    className={styles.inputRight}
                    placeholder={t(LanguageUid.DF_HEIGHT)}
                    onKeyUp={(event) => {
                      onsetAbrForm(event);
                    }}
                  />
                </div>
              </div> */}
            </div>
            <div
              className={`col-5 col-t-6  ${screenWidth <= 790 ? "" : "pl-5"}`}
            >
              <fieldset className={styles.result}>
                <legend>{t(LanguageUid.DT_RESULT)}</legend>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <label>{t(LanguageUid.DT_APG)}</label>
                        <span>
                          {IsNullOrEmpty(abrDeliveryFee.apg)
                            ? "..."
                            : abrDeliveryFee.apg == "-1"
                            ? "..."
                            : abrDeliveryFee.apg}
                        </span>
                      </td>
                      <td>
                        <label>{t(LanguageUid.DT_PACKAGE)}</label>
                        <span>
                          {IsNullOrEmpty(abrDeliveryFee.koli)
                            ? "..."
                            : abrDeliveryFee.koli == "-1"
                            ? "..."
                            : abrDeliveryFee.koli}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{t(LanguageUid.DT_TURPEX)}</label>
                        <span>
                          {IsNullOrEmpty(abrDeliveryFee.turpex)
                            ? "..."
                            : abrDeliveryFee.turpex == "-1"
                            ? "..."
                            : abrDeliveryFee.turpex}
                        </span>
                      </td>
                      <td>
                        <label>{t(LanguageUid.DF_REGISTERED)}</label>
                        <span>
                          {IsNullOrEmpty(abrDeliveryFee.taahhutlu)
                            ? "..."
                            : abrDeliveryFee.taahhutlu == "-1"
                            ? "..."
                            : abrDeliveryFee.taahhutlu}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
            {errorVisible && <ReturnError />}
          </div>

          <div className={styles.feeFooter}>
            <Link
                title={t(LanguageUid.DF_DETAIL_POST)}
                href="/gonderi-ucreti-hesaplama"
              >
                <p className={styles.formLink}>
                  {t(LanguageUid.DF_DETAIL_POST)}
                </p>
              </Link>

            <div className="col-t-2 ml-6">
              <button
                className={styles.findButton}
                onClick={async () => {
                  await onGetAbroadDeliveryFee();
                }}
              >
                <i className="fa-solid fa-magnifying-glass-arrow-right"></i>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default DeliveryFee;
