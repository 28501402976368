import UseTranslation from "next-translate/useTranslation";
import React, { useState as UseState } from "react";
import {
  LanguageUid,
  ShipmentTrackingProcessType,
} from "../../../../../libs/enums";
import { IsNullOrEmpty } from "../../../../../libs/utils";
import {
  SVG_Accepted,
  SVG_Distribution,
  SVG_Transfer,
  SVG_Truck,
  SVG_WasDelivered,
} from "./icons";
import styles from "./styles.module.css";

const ShipmentTrackingPreview = ({ model, isActive }) => {
  const { t } = UseTranslation("GetLanguageResourceResponse");
  const [deliveryInformationActive, setDeliveryInformationActive] =
    UseState(false);
  const [feesActive, setFeesActive] = UseState(false);

  var move = [];
  var moveCount =
    model && !IsNullOrEmpty(model[0].hareketDongu)
      ? model[0].hareketDongu.length - 1
      : 0;
  const _lastDescription =
    model && !IsNullOrEmpty(model[0].hareketDongu)
      ? model[0].hareketDongu[moveCount].aciklama
      : "";

  for (let i = moveCount; i >= 0; i--) {
    if (model && !IsNullOrEmpty(model[0].hareketDongu)) {
      move.push(model[0].hareketDongu[i]);
    }
  }

  var acceptedDate_y =
    model && model[0].kabul.kabul_tarihi.toString().substring(0, 4);
  var acceptedDate_m =
    model && model[0].kabul.kabul_tarihi.toString().substring(4, 6);
  var acceptedDate_d =
    model && model[0].kabul.kabul_tarihi.toString().substring(6);

  const CardInformations = {
    Process:
      model &&
      IsNullOrEmpty(
        model[0].hareketDongu && model[0].hareketDongu[moveCount].aciklama
      )
        ? "..."
        : model && model[0].hareketDongu[moveCount].aciklama,
    Barcode:
      model && IsNullOrEmpty(model[0].kabul.barkod_no)
        ? "..."
        : model && model[0].kabul.barkod_no,
    Sender:
      model && IsNullOrEmpty(model[0].kabul.gonderici)
        ? "..."
        : model && model[0].kabul.gonderici,
    Sender_Address:
      model && IsNullOrEmpty(model[0].kabul.gonderici_adres)
        ? "..."
        : model && model[0].kabul.gonderici_adres,
    Sender_Acceptance_Date:
      model && IsNullOrEmpty(model[0].kabul.kabul_tarihi)
        ? "..."
        : `${acceptedDate_d}/${acceptedDate_m}/${acceptedDate_y}`,
    Buyer:
      model && IsNullOrEmpty(model[0].kabul.alici)
        ? "..."
        : model && model[0].kabul.alici,
    Buyer_Address:
      model && IsNullOrEmpty(model[0].kabul.alici_adres)
        ? "..."
        : model && model[0].kabul.alici_adres,
    Buyer_Description:
      model && IsNullOrEmpty(model[0].sondurum.teslim_alan)
        ? "..."
        : model && model[0].sondurum.teslim_alan,
  };

  const DeliveryInformation = {
    PostType:
      model && IsNullOrEmpty(model[0].kabul.reserve1)
        ? "-"
        : model && model[0].kabul.reserve1,
    Weight_Volume:
      model && `${model[0].kabul.agirlik} / ${model[0].kabul.desi}`,
    Sevices:
      model && IsNullOrEmpty(model[0].kabul.ekhizmetler)
        ? "-"
        : model && model[0].kabul.ekhizmetler,
    CustomsDuty:
      model && IsNullOrEmpty(model[0].kabul.gumruk_vergisi)
        ? "0.00 ₺"
        : model && `${model[0].kabul.gumruk_vergisi} ₺`,
    StampDuty:
      model && IsNullOrEmpty(model[0].kabul.damga_vergisi)
        ? "0.00 ₺"
        : model && `${model[0].kabul.damga_vergisi} ₺`,
    PresentationToCustomsFee:
      model && IsNullOrEmpty(model[0].kabul.gumruge_sunma_ucreti)
        ? "0.00 ₺"
        : model && `${model[0].kabul.gumruge_sunma_ucreti} ₺`,
    OverseasReturnFee:
      model && IsNullOrEmpty(model[0].kabul.yd_iade_ucreti)
        ? "0.00 ₺"
        : model && `${model[0].kabul.yd_iade_ucreti} ₺`,
    PaymentTermFee:
      model && IsNullOrEmpty(model[0].kabul.odeme_sarti_bedeli)
        ? "0.00 ₺"
        : model && `${model[0].kabul.odeme_sarti_bedeli} ₺`,
    Total:
      model && IsNullOrEmpty(model[0].kabul.toplam_yd_ucret)
        ? "0.00 ₺"
        : model && `${model[0].kabul.toplam_yd_ucret} ₺`,
  };

  const onIsActiveProcess = (_process_) => {
    let style = `${styles["iconShadow"]} `;

    if (
      _lastDescription === _process_ ||
      _lastDescription.includes(_process_)
    ) {
      return (style += `${styles["activeIcon"]}`);
    }
  };

  let _lDesc = false;
  const isProcessEqual = (_process_) => {
    if (
      _lastDescription === _process_ &&
      !(
        _process_ === ShipmentTrackingProcessType.WAS_DELIVERED ||
        _process_ === ShipmentTrackingProcessType.DROPPED_TO_THE_CARGOMAT ||
        _process_ === ShipmentTrackingProcessType.PTT ||
        _process_ === ShipmentTrackingProcessType.UNDELIVERABLE ||
        _process_ === ShipmentTrackingProcessType.RETURN
      )
    ) {
      _lDesc = true;
      return "255, 199, 44";
    }

    if (_process_ === ShipmentTrackingProcessType.ACCEPTED) {
      return _lDesc ? "83, 86, 90" : "0, 169, 206";
    } else if (_process_ === ShipmentTrackingProcessType.TRANSFER_PROCESS) {
      return _lDesc ? "83, 86, 90" : "0, 169, 206";
    } else if (
      _process_ === ShipmentTrackingProcessType.IN_INTRASTATE_TRANSFER
    ) {
      return _lDesc ? "83, 86, 90" : "0, 169, 206";
    } else if (_process_ === ShipmentTrackingProcessType.DISTRIBUTION) {
      return _lDesc ? "83, 86, 90" : "0, 169, 206";
    } else if (_process_ === ShipmentTrackingProcessType.WAS_DELIVERED) {
      return _lDesc ? "83, 86, 90" : "48, 199, 129";
    } else if (
      _process_ === ShipmentTrackingProcessType.DROPPED_TO_THE_CARGOMAT
    ) {
      return _lDesc ? "83, 86, 90" : "48, 199, 129";
    } else if (_process_ === ShipmentTrackingProcessType.PTT) {
      return _lDesc ? "83, 86, 90" : "48, 199, 129";
    } else if (_process_ === ShipmentTrackingProcessType.UNDELIVERABLE) {
      return _lDesc ? "83, 86, 90" : "227, 74, 74";
    } else if (_process_ === ShipmentTrackingProcessType.RETURN) {
      return _lDesc ? "83, 86, 90" : "227, 74, 74";
    }
  };

  const onShipmentTrackingProcess = (_process_) => {
    return {
      isActive: onIsActiveProcess(_process_),
      icon: {
        border: `solid .5rem rgb(${isProcessEqual(_process_)})`,
      },
      iconPath: {
        border: `solid .5rem rgba(${isProcessEqual(_process_)}, .3)`,
      },
      svgColor: isProcessEqual(_process_),
    };
  };

  const onLastProcess = () => {
    function Template(__p) {
      if (__p === ShipmentTrackingProcessType.WAS_DELIVERED) {
        __p = "WAS_DELIVERED";
      } else if (__p === ShipmentTrackingProcessType.DROPPED_TO_THE_CARGOMAT) {
        __p = "DROPPED_TO_THE_CARGOMAT";
      } else if (__p === ShipmentTrackingProcessType.PTT) {
        __p = "PTT";
      } else if (__p === ShipmentTrackingProcessType.UNDELIVERABLE) {
        __p = "UNDELIVERABLE";
      } else if (__p === ShipmentTrackingProcessType.RETURN) {
        __p = "RETURN";
      }

      return (
        <div
          className={styles.icon}
          style={
            onShipmentTrackingProcess(ShipmentTrackingProcessType[__p]).icon
          }
        >
          <div
            className={
              onShipmentTrackingProcess(ShipmentTrackingProcessType[__p])
                .isActive
            }
          ></div>
          {SVG_WasDelivered(
            onShipmentTrackingProcess(ShipmentTrackingProcessType[__p]).svgColor
          )}
        </div>
      );
    }

    if (_lastDescription.includes(ShipmentTrackingProcessType.WAS_DELIVERED)) {
      return Template(ShipmentTrackingProcessType.WAS_DELIVERED);
    } else if (
      _lastDescription.includes(
        ShipmentTrackingProcessType.DROPPED_TO_THE_CARGOMAT
      )
    ) {
      return Template(ShipmentTrackingProcessType.DROPPED_TO_THE_CARGOMAT);
    } else if (_lastDescription.includes(ShipmentTrackingProcessType.PTT)) {
      return Template(ShipmentTrackingProcessType.PTT);
    } else if (
      _lastDescription.includes(ShipmentTrackingProcessType.UNDELIVERABLE)
    ) {
      return Template(ShipmentTrackingProcessType.UNDELIVERABLE);
    } else if (_lastDescription.includes(ShipmentTrackingProcessType.RETURN)) {
      return Template(ShipmentTrackingProcessType.RETURN);
    } else {
      return Template();
    }
  };

  const onProcessTranslate = (_process_) => {
    if (_process_ && _process_.includes(ShipmentTrackingProcessType.ACCEPTED)) {
      return t(LanguageUid.ST_ACCEPTED);
    } else if (
      _process_ &&
      _process_.includes(ShipmentTrackingProcessType.TRANSFER_PROCESS)
    ) {
      return t(LanguageUid.ST_TRANSFER_PROCESS);
    } else if (
      _process_ &&
      _process_.includes(ShipmentTrackingProcessType.IN_INTRASTATE_TRANSFER)
    ) {
      return t(LanguageUid.ST_IN_INTRASTATE_TRANSFER);
    } else if (
      _process_ &&
      _process_.includes(ShipmentTrackingProcessType.DISTRIBUTION)
    ) {
      return t(LanguageUid.ST_DISTRIBUTION);
    } else if (
      _process_ &&
      _process_.includes(ShipmentTrackingProcessType.WAS_DELIVERED)
    ) {
      return t(LanguageUid.ST_WAS_DELIVERED);
    } else if (
      _process_ &&
      _process_.includes(ShipmentTrackingProcessType.DROPPED_TO_THE_CARGOMAT)
    ) {
      return t(LanguageUid.ST_DROPPED_TO_THE_CARGOMAT);
    } else if (
      _process_ &&
      _process_.includes(ShipmentTrackingProcessType.PTT)
    ) {
      return t(LanguageUid.ST_PTT_IN_THE_WORKPLACE);
    } else if (
      _process_ &&
      _process_.includes(ShipmentTrackingProcessType.UNDELIVERABLE)
    ) {
      return t(LanguageUid.ST_UNDELIVERABLE);
    } else if (
      _process_ &&
      _process_.includes(ShipmentTrackingProcessType.RETURN)
    ) {
      return t(LanguageUid.ST_RETURN);
    } else {
      return "";
    }
  };

  return (
    <>
      <div className={styles.shipmentTrackingPreviewModal}>
        <i
          onClick={() => {
            isActive(false);
            document.body.style.overflow = "auto";
          }}
          className={`${styles.close} fa-solid fa-xmark`}
        ></i>

        <div className={styles.shipmentTracking}>
          <div className={styles.states}>
            <div className={styles.state}>
              <div className={styles.icons}>
                <div
                  className={styles.icon}
                  style={
                    onShipmentTrackingProcess(
                      ShipmentTrackingProcessType.ACCEPTED
                    ).icon
                  }
                >
                  <div
                    className={styles.iconPath}
                    style={
                      onShipmentTrackingProcess(
                        ShipmentTrackingProcessType.ACCEPTED
                      ).iconPath
                    }
                  ></div>
                  <div
                    className={
                      onShipmentTrackingProcess(
                        ShipmentTrackingProcessType.ACCEPTED
                      ).isActive
                    }
                  ></div>
                  {SVG_Accepted(
                    onShipmentTrackingProcess(
                      ShipmentTrackingProcessType.ACCEPTED
                    ).svgColor
                  )}
                </div>
                <div
                  className={styles.icon}
                  style={
                    onShipmentTrackingProcess(
                      ShipmentTrackingProcessType.TRANSFER_PROCESS
                    ).icon
                  }
                >
                  <div
                    className={styles.iconPath}
                    style={
                      onShipmentTrackingProcess(
                        ShipmentTrackingProcessType.TRANSFER_PROCESS
                      ).iconPath
                    }
                  ></div>
                  <div
                    className={
                      onShipmentTrackingProcess(
                        ShipmentTrackingProcessType.TRANSFER_PROCESS
                      ).isActive
                    }
                  ></div>
                  {SVG_Truck(
                    onShipmentTrackingProcess(
                      ShipmentTrackingProcessType.TRANSFER_PROCESS
                    ).svgColor
                  )}
                </div>
                <div
                  className={styles.icon}
                  style={
                    onShipmentTrackingProcess(
                      ShipmentTrackingProcessType.IN_INTRASTATE_TRANSFER
                    ).icon
                  }
                >
                  <div
                    className={styles.iconPath}
                    style={
                      onShipmentTrackingProcess(
                        ShipmentTrackingProcessType.IN_INTRASTATE_TRANSFER
                      ).iconPath
                    }
                  ></div>
                  <div
                    className={
                      onShipmentTrackingProcess(
                        ShipmentTrackingProcessType.IN_INTRASTATE_TRANSFER
                      ).isActive
                    }
                  ></div>
                  {SVG_Transfer(
                    onShipmentTrackingProcess(
                      ShipmentTrackingProcessType.IN_INTRASTATE_TRANSFER
                    ).svgColor
                  )}
                </div>
                <div
                  className={styles.icon}
                  style={
                    onShipmentTrackingProcess(
                      ShipmentTrackingProcessType.DISTRIBUTION
                    ).icon
                  }
                >
                  <div
                    className={styles.iconPath}
                    style={
                      onShipmentTrackingProcess(
                        ShipmentTrackingProcessType.DISTRIBUTION
                      ).iconPath
                    }
                  ></div>
                  <div
                    className={
                      onShipmentTrackingProcess(
                        ShipmentTrackingProcessType.DISTRIBUTION
                      ).isActive
                    }
                  ></div>
                  {SVG_Distribution(
                    onShipmentTrackingProcess(
                      ShipmentTrackingProcessType.DISTRIBUTION
                    ).svgColor
                  )}
                </div>

                {/* Start Last Process */}
                {onLastProcess()}
                {/* End Last Process */}
              </div>

              <div className={styles.progress}>
                <h1>
                  {CardInformations.Process &&
                    CardInformations.Process.includes(
                      ShipmentTrackingProcessType.DROPPED_TO_THE_CARGOMAT
                    ) && (
                      <>
                        {CardInformations.Process.replace(
                          "KARGOMATA BIRAKILDI",
                          " "
                        )}
                        <br />
                      </>
                    )}

                  {onProcessTranslate(CardInformations.Process)}
                </h1>
                <h2>{CardInformations.Barcode}</h2>
                <div className={styles.panel}>
                  {/* Sender Start */}
                  <div>
                    <p>
                      <i className="fa-solid fa-user-large"></i>
                      <label>
                        <span>{t(LanguageUid.ST_SENDER)}</span>
                        <span translate="no">{CardInformations.Sender}</span>
                      </label>
                    </p>
                    <p>
                      <i className="fa-solid fa-location-dot"></i>
                      <label>
                        <span>{t(LanguageUid.ST_SENDER_ADDRESS)}</span>
                        <span translate="no">
                          {CardInformations.Sender_Address}
                        </span>
                      </label>
                    </p>
                    <p>
                      <i className="fa-solid fa-calendar-days"></i>
                      <label>
                        <span>{t(LanguageUid.ST_ACCEPTED_DATE)}</span>
                        <span>{CardInformations.Sender_Acceptance_Date}</span>
                      </label>
                    </p>
                  </div>
                  {/* Sender End */}

                  {/* Delivery Area Start */}
                  <div>
                    <p>
                      <i className="fa-solid fa-user-large"></i>
                      <label>
                        <span>{t(LanguageUid.ST_BUYER)}</span>
                        <span translate="no">{CardInformations.Buyer}</span>
                      </label>
                    </p>
                    <p>
                      <i className="fa-solid fa-location-dot"></i>
                      <label>
                        <span>{t(LanguageUid.ST_BUYER_ADDRESS)}</span>
                        <span translate="no">
                          {CardInformations.Buyer_Address}
                        </span>
                      </label>
                    </p>
                    <p>
                      <i className="fa-solid fa-calendar-days"></i>
                      <label>
                        <span>{t(LanguageUid.ST_DELIVERY_AREA)}</span>
                        <span translate="no">
                          {CardInformations.Buyer_Description}
                        </span>
                      </label>
                    </p>
                  </div>
                  {/* Delivery Area End */}
                </div>

                {/* Delivery Information Start */}
                <span
                  onClick={() => {
                    setDeliveryInformationActive(!deliveryInformationActive);
                  }}
                  className={`${styles["deliveryInformationOpenButton"]} ${
                    deliveryInformationActive &&
                    styles["deliveryInformationActive"]
                  }`}
                >
                  {t(LanguageUid.ST_DELIVERY_INFORMATION)}
                  <span
                    className={`${styles["arrow"]} ${
                      deliveryInformationActive && styles["arrowOpen"]
                    }`}
                  ></span>
                  <span className={styles.arrowBack}></span>
                </span>
                <div
                  className={`${styles["deliveryInformation"]} ${
                    deliveryInformationActive &&
                    styles["deliveryInformationActive"]
                  }`}
                >
                  <div className={styles.body}>
                    <div>
                      <h6>
                        <i className="fa-solid fa-box"></i>
                        <span>{t(LanguageUid.ST_POST_TYPE)}</span>
                      </h6>
                      <span>{DeliveryInformation.PostType}</span>
                    </div>
                    <div>
                      <h6>
                        <i className="fa-solid fa-weight-scale"></i>
                        <span>{t(LanguageUid.ST_WEIGHT_VOLUME)}</span>
                      </h6>
                      <span>{DeliveryInformation.Weight_Volume}</span>
                    </div>
                    <div>
                      <h6>
                        <i className="fa-solid fa-plus"></i>
                        <span>{t(LanguageUid.ST_ADDITIONAL_SERVICES)}</span>
                      </h6>
                      <span>{DeliveryInformation.Sevices}</span>
                    </div>
                  </div>
                </div>
                {/* Delivery Information End */}

                {/* Fees Start */}
                <span
                  onClick={() => {
                    setFeesActive(!feesActive);
                  }}
                  className={`${styles["feesOpenButton"]} ${
                    feesActive && styles["feesActive"]
                  }`}
                >
                  {t(LanguageUid.ST_FEES)}
                  <span
                    className={`${styles["arrow"]} ${
                      feesActive && styles["arrowOpen"]
                    }`}
                  ></span>
                  <span className={styles.arrowBack}></span>
                </span>
                <div
                  className={`${styles["fees"]} ${
                    feesActive && styles["feesActive"]
                  }`}
                >
                  <div className={styles.body}>
                    <div>
                      <h6>
                        <i className="fa-solid fa-money-bill"></i>
                        <span>{t(LanguageUid.ST_CUSTOMS_DUTY)}</span>
                      </h6>
                      <span>{DeliveryInformation.CustomsDuty}</span>
                    </div>
                    <div>
                      <h6>
                        <i className="fa-solid fa-stamp"></i>
                        <span>{t(LanguageUid.ST_STAMP_DUTY)}</span>
                      </h6>
                      <span>{DeliveryInformation.StampDuty}</span>
                    </div>
                    <div>
                      <h6>
                        <i className="fa-solid fa-file-invoice"></i>
                        <span>
                          {t(LanguageUid.ST_PRESENTATION_TO_CUSTOMS_FEE)}
                        </span>
                      </h6>
                      <span>
                        {DeliveryInformation.PresentationToCustomsFee}
                      </span>
                    </div>
                    <div>
                      <h6>
                        <i className="fa-solid fa-receipt"></i>
                        <span>{t(LanguageUid.ST_OVERSEAS_RETURN_FEE)}</span>
                      </h6>
                      <span>{DeliveryInformation.OverseasReturnFee}</span>
                    </div>
                    <div>
                      <h6>
                        <i className="fa-solid fa-receipt"></i>
                        <span>{t(LanguageUid.ST_PAYMENT_TERM_FEE)}</span>
                      </h6>
                      <span>{DeliveryInformation.PaymentTermFee}</span>
                    </div>
                    <div>
                      <h6>
                        <i className="fa-solid fa-cash-register"></i>
                        <span>{t(LanguageUid.ST_TOTAL)}</span>
                      </h6>
                      <span>{DeliveryInformation.Total}</span>
                    </div>
                  </div>
                </div>
                {/* Fees End */}
              </div>
            </div>

            <div className={styles.history}>
              <ul>
                {move &&
                  move.map((loop, index) => (
                    <li key={index}>
                      {index !== 0 ? (
                        <i
                          className="fa-solid fa-circle-check"
                          style={{ color: "var(--pttGreenColor)" }}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-circle-chevron-up"
                          style={{ color: "var(--pttYellowColor)" }}
                        ></i>
                      )}

                      <p>
                        <label>
                          <div
                            style={{
                              backgroundColor:
                                index === 0
                                  ? "rgb(255, 199, 44)"
                                  : "rgb(221, 221, 221)",
                            }}
                          >
                            <label>
                              {!loop.mazbata
                                ? onProcessTranslate(loop.aciklama)
                                : `${onProcessTranslate(loop.aciklama)} [${
                                    loop.mazbata_aciklama
                                  }]`}
                            </label>
                            <span>{loop.tarih}</span>
                            <span>{loop.saat}</span>
                          </div>

                          <div>
                            <div>
                              <div>
                                <label>{t(LanguageUid.ST_BRANCH)}</label> <br />
                                <span>{loop.isyeri}</span>
                              </div>

                              <div>
                                <label>{t(LanguageUid.ST_CITY_COUNTY)}</label>
                                <br />
                                <span>
                                  {loop.il} / {loop.ilce}
                                </span>
                              </div>
                            </div>

                            <div>
                              <label>{t(LanguageUid.ST_DESCRIPTION)}</label>
                              <span>
                                {IsNullOrEmpty(loop.islem_detay)
                                  ? "-"
                                  : loop.islem_detay}
                              </span>
                            </div>
                          </div>
                        </label>
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentTrackingPreview;
