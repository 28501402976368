import { ActionTypes } from "../../libs/enums";

const GetAction_Paging = (page, showCount, start, end) => {
  return {
    type: ActionTypes.GET_PAGING,
    payload: {
      page: page,
      showCount: showCount,
      start: start,
      end: end,
    },
  };
};

const UpdateAction_Paging = (page, showCount, start, end) => {
  return {
    type: ActionTypes.UPDATE_PAGING,
    payload: {
      page: page,
      showCount: showCount,
      start: start,
      end: end,
    },
  };
};

export { GetAction_Paging, UpdateAction_Paging };
