import { GetAction_Paging, UpdateAction_Paging } from "../actions/paging";

const Get_Paging = (page, showCount, start, end) => {
  return (dispatch) => {
    dispatch(GetAction_Paging(page, showCount, start, end));
  };
};

const Update_Paging = (page, showCount, start, end) => {
  return (dispatch) => {
    dispatch(UpdateAction_Paging(page, showCount, start, end));
  };
};

export { Get_Paging, Update_Paging };
