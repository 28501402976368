export const SVG_Accepted = (color) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 422.91 422.91"
      style={{ fill: color }}
    >
      <path
        d="M404.91,91.106h-70.569V69.755c0-16.817-13.683-30.5-30.5-30.5H119.07c-16.818,0-30.5,13.683-30.5,30.5v21.352H18
c-9.925,0-18,8.075-18,18v204.966c0,9.925,8.075,18,18,18h76.468c2.911,3.968,6.755,7.208,11.208,9.395v19.188
c0,12.683,10.318,23,23,23h24.658c12.682,0,23-10.317,23-23v-16.083h70.24v16.083c0,12.683,10.317,23,23,23h24.658
c12.683,0,23-10.317,23-23v-19.188c4.454-2.187,8.298-5.427,11.209-9.395h76.468c9.925,0,18-8.075,18-18V109.106
C422.91,99.182,414.835,91.106,404.91,91.106z M189.504,240.467l21.952-24.754l21.951,24.753h-11.458v29.251h-20.989v-29.25H189.504
z M299.341,74.255v195.462h-23.292v-79.045c0-8.271-6.729-15-15-15h-2.713v-37.445c0-8.271-6.729-15-15-15h-63.762
c-8.271,0-15,6.729-15,15v37.445h-2.713c-8.271,0-15,6.729-15,15v79.045H123.57V74.255H299.341z"
      />
    </svg>
  );
};

export const SVG_Truck = (color) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 462.522 462.522"
      style={{ fill: color }}
    >
      <path
        d="M432.958,222.262c-1.452-0.305-2.823-0.592-4.042-0.909c-13.821-3.594-20.129-5.564-24.793-14.569l-17.667-35.768
      c-5.678-10.961-20.339-19.879-32.682-19.879h-31.453v-41.303c0-7.416-6.034-13.45-13.452-13.45l-219.07,0.22
      c-7.218,0-12.661,5.736-12.661,13.343v12.208H21.018C9.429,122.156,0,131.584,0,143.174s9.429,21.018,21.018,21.018h56.119v20.145
      H40.394c-11.589,0-21.018,9.429-21.018,21.018s9.429,21.018,21.018,21.018h36.743v20.145H59.77
      c-11.589,0-21.018,9.429-21.018,21.018s9.429,21.018,21.018,21.018h17.367v21.07c0,7.416,6.034,13.45,13.45,13.45h22.788
      c3.549,24.323,24.542,43.064,49.837,43.064c25.297,0,46.291-18.741,49.841-43.064h92.224c0.479,0,0.97-0.032,1.46-0.064
      c3.522,24.354,24.528,43.128,49.845,43.128c25.297,0,46.291-18.741,49.841-43.064h32.732c12.885,0,23.368-10.482,23.368-23.366
      v-39.648C462.522,228.465,444.73,224.732,432.958,222.262z M356.582,297.46c10.1,0,18.317,8.214,18.317,18.311
      s-8.217,18.311-18.317,18.311c-10.096,0-18.31-8.214-18.31-18.311S346.486,297.46,356.582,297.46z M322.321,219.414v-48.77h24.036
      c9.238,0,20.634,6.932,24.864,15.094l15.721,31.829c0.333,0.644,0.679,1.258,1.038,1.846H322.321z M181.529,315.77
      c0,10.096-8.217,18.311-18.317,18.311c-10.096,0-18.309-8.214-18.309-18.311s8.213-18.311,18.309-18.311
      C173.312,297.46,181.529,305.674,181.529,315.77z"
      />
    </svg>
  );
};

export const SVG_Transfer = (color) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 426.949 426.949"
      style={{ fill: color }}
    >
      <g>
        <path
          d="M212.914,29.347c2.469,5.196,8.668,9.201,14.419,9.314l14.158,0.281c-0.822,3.595-1.271,7.33-1.271,11.17
      c0,27.633,22.48,50.114,50.113,50.114s50.113-22.481,50.113-50.114C340.448,22.48,317.967,0,290.335,0
      c-9.826,0-18.993,2.854-26.737,7.76l-46.104-0.106c-3.58,0-6.595,1.523-8.273,4.178c-1.679,2.657-1.76,6.037-0.222,9.274
      L212.914,29.347z"
        />
        <path
          d="M192.883,267.478H44.125c-8.271,0-15,6.729-15,15v129.472c0,8.271,6.729,15,15,15h148.759c8.271,0,15-6.729,15-15V282.478
      C207.883,274.207,201.154,267.478,192.883,267.478z M133.362,357.14v43.667h-29.719v-43.665h-16.22l31.082-35.049l31.08,35.047
      H133.362z"
        />
        <path
          d="M394.372,178.833l-38.36-53.447c-4.178-5.82-10.379-10.208-19.894-10.208h-0.01H129.514c-8.271,0-15,6.729-15,15v8.667
      c0,8.271,6.729,15,15,15h108.051v255.105c0,9.925,8.075,18,18,18h12.31c9.925,0,18-8.075,18-18V284.003h8.925v124.946
      c0,9.925,8.075,18,18,18h12.309c9.925,0,18-8.075,18-18V262.176v-3.968h2.188c7.254,0,15.689-4.598,19.622-10.694l30.005-46.527
      C398.996,194.67,398.753,184.939,394.372,178.833z M343.108,165.743l15.81,22.688c1.94,2.786,1.921,7.845-0.042,10.615
      L343.108,221.3V165.743z"
        />
      </g>
    </svg>
  );
};

export const SVG_Distribution = (color) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 453.322 453.322"
      style={{ fill: color }}
    >
      <g>
        <path
          d="M420.806,255.459c-1.61-0.338-3.132-0.657-4.488-1.009c-14.98-3.896-22.413-6.216-27.62-16.269l-19.555-39.592
        c-6.244-12.055-22.368-21.863-35.942-21.863h-35.087v-26.971c0-8.063-6.56-14.622-14.625-14.622l-83.815,0.088
        c-1.991-53.33-45.989-96.104-99.798-96.104C44.804,39.116,0,83.921,0,138.993c0,31.138,14.326,58.99,36.729,77.321V351.91
        c0,8.063,6.56,14.622,14.622,14.622h25.463c3.813,26.908,26.99,47.674,54.937,47.674c27.949,0,51.127-20.766,54.939-47.674h92.821
        c0.606,0,1.227-0.034,1.851-0.081c3.777,26.948,26.971,47.755,54.946,47.755c27.949,0,51.128-20.766,54.94-47.674h36.472
        c14.117,0,25.602-11.484,25.602-25.6v-43.892C453.322,262.281,433.754,258.176,420.806,255.459z M99.876,69.116
        c38.529,0,69.876,31.346,69.876,69.876s-31.347,69.876-69.876,69.876S30,177.522,30,138.993S61.347,69.116,99.876,69.116z
        M131.751,379.252c-11.324,0-20.537-9.213-20.537-20.538c0-11.325,9.213-20.538,20.537-20.538c11.328,0,20.545,9.213,20.545,20.538
        C152.296,370.039,143.079,379.252,131.751,379.252z M336.309,379.252c-11.323,0-20.536-9.213-20.536-20.538
        c0-11.325,9.213-20.538,20.536-20.538c11.329,0,20.546,9.213,20.546,20.538C356.854,370.039,347.638,379.252,336.309,379.252z
        M298.113,252.312v-54.525h26.876c10.315,0,23.04,7.74,27.766,16.859l17.401,35.232c0.443,0.857,0.907,1.667,1.394,2.435H298.113z"
        />
        <path
          d="M100.933,156.728l26.065,8.259c1.507,0.478,3.033,0.705,4.534,0.705c6.364,0,12.271-4.083,14.296-10.473
      c2.502-7.897-1.872-16.328-9.77-18.83l-21.021-6.66l-2.201-2.798v-19.065c0-8.284-6.716-15-15-15s-15,6.716-15,15v24.147
      C82.837,143.011,90.447,153.406,100.933,156.728z"
        />
      </g>
    </svg>
  );
};

export const SVG_WasDelivered = (color) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 453.296 453.296"
      style={{ fill: color }}
    >
      <g>
        <path
          d="M154.914,211.842l-35.808-0.549c-3.085-0.048-6.211-2.763-6.69-5.811l-8.197-52.109c-0.514-3.267-1.813-5.513-3.408-6.975
          c-2.968-4.224-7.957-6.936-13.489-6.69l-45.215,1.969c-8.697,0.379-15.771,7.771-15.768,16.476l0.038,108.34
          c0,0.261,0.025,0.514,0.038,0.77c-0.004,0.131-0.019,0.257-0.02,0.389c-0.032,5.716-0.333,15.232-0.672,21.214l-1.47,26.033
          c-0.309,5.462-2.437,14.303-4.647,19.306L1.302,375.629c-1.764,3.991-1.734,8.403,0.08,12.105c1.815,3.702,5.285,6.428,9.52,7.479
          l1.743,0.433c1.325,0.329,2.711,0.496,4.12,0.496c7.182,0,14.052-4.168,17.095-10.372l17.105-34.865
          c2.805-5.718,6.298-15.411,7.786-21.607l9.698-40.397l23.563,36.034c2.86,4.374,5.884,12.768,6.471,17.961l4.536,40.179
          c0.954,8.454,8.654,15.332,17.165,15.332l2.557-0.009c4.353-0.016,8.311-1.753,11.144-4.893s4.158-7.254,3.729-11.585
          l-4.834-48.738c-0.646-6.513-2.985-16.401-5.325-22.514l-22.727-59.361l-0.131-11.704l51.643-1.912
          c4.474-0.165,8.569-1.978,11.236-4.973c2.144-2.407,3.191-5.408,2.951-8.451C169.886,217.423,163.072,211.966,154.914,211.842z
          M120.183,393.404L120.183,393.404v0.002V393.404z"
        />
        <path
          d="M55.676,129.975c4.378,0,8.707-0.747,12.866-2.22c9.709-3.437,17.5-10.449,21.935-19.745
          c2.962-6.209,4.181-12.94,3.646-19.62l9.726-1.098c5.717-0.646,11.52-5.205,13.498-10.606l3.058-8.351
          c1.173-3.205,0.864-6.45-0.849-8.901c-1.67-2.391-4.484-3.761-7.719-3.761c-0.376,0-0.764,0.018-1.162,0.056l-34.163,3.25
          c-6.086-3.916-13.28-6.14-20.84-6.14c-4.376,0-8.705,0.746-12.865,2.219c-20.043,7.098-30.576,29.177-23.479,49.217
          C24.769,119.647,39.377,129.975,55.676,129.975z"
        />
        <path
          d="M211.668,137.77c-0.561-8.057-7.587-14.611-15.691-14.611l-64.514,0.112c-4.06,0.007-7.792,1.618-10.509,4.535
          c-2.716,2.918-4.058,6.755-3.775,10.805l3.276,47.082c0.56,8.058,7.587,14.612,15.666,14.612l64.539-0.113
          c4.06-0.007,7.792-1.616,10.509-4.534c2.717-2.917,4.059-6.755,3.776-10.806L211.668,137.77z M136.12,195.304L136.12,195.304
          L136.12,195.304L136.12,195.304z"
        />
        <path
          d="M368.163,142.774c22.376,0,40.581-18.204,40.581-40.581c0-22.376-18.205-40.58-40.581-40.58
          c-22.376,0-40.58,18.204-40.58,40.58C327.583,124.57,345.787,142.774,368.163,142.774z"
        />
        <path
          d="M449.843,201.382l-27.791-38.721c-3.403-4.742-10.531-10.209-22.501-10.209c-16.766,0-67.063,0-67.063,0
          c-5.968,0-13.778,3.03-17.796,5.07l-21.339,10.837c-2.035,1.033-5.274,1.676-8.454,1.676c-2.246,0-4.312-0.31-5.817-0.872
          l-34.526-12.896c-1.613-0.602-3.235-0.907-4.821-0.907c-6.545,0-11.296,5.086-11.296,12.093v2.609
          c0,7.56,5.554,15.535,12.645,18.158l35.029,12.955c3.166,1.171,7.296,1.816,11.629,1.816c0,0,0,0,0,0
          c3.917,0,7.668-0.544,10.563-1.53l27.183-9.265v190.261c0,9.925,8.075,18,18,18h4.215c9.925,0,18-8.075,18-18v-94.74h4.925v94.74
          c0,9.925,8.075,18,18,18h4.214c9.925,0,18-8.075,18-18V267.311c6.9-0.552,14.504-4.936,18.177-10.632l21.375-33.145
          C454.467,217.218,454.225,207.488,449.843,201.382z M410.843,196.754l9.337,13.4c1.941,2.785,1.921,7.845-0.042,10.615
          l-9.295,13.119V196.754z"
        />
      </g>
    </svg>
  );
};
