import React, {
  useEffect as UseEffect,
  useRef as UseRef,
  useState as UseState,
} from "react";
import {
  FileType,
  LanguageUid,
  LanguageValueType,
  SliderButton,
  Language,
} from "../../libs/enums";
import ShipmentTracking from "../views/home_page/shipment_tracking/index";
import styles from "./styles.module.css";
import UseTranslation from "next-translate/useTranslation";
import { GetLanguageResource, IsNullOrEmpty } from "../../libs/utils";
import { CDN_URL } from "../../libs/generalSettings";
import Image from "next/image";
import { GetMimeType } from "../../libs/utils";
import Link from "next/link";

var index = 0;

const Slider = (props) => {
  const { t, lang } = UseTranslation("GetLanguageResourceResponse");
  const { sliders, panel, butons, height } = props;
  // const Texts = [
  //   t(LanguageUid.SHIPMENT_TRACKING_TITLE),
  //   t(LanguageUid.DELIVERY_FEE_TITLE),
  //   t(LanguageUid.NEAREST_BRANCH_TITLE),
  //   t(LanguageUid.DT_DELİVERY_TIMES),
  // ];
  // const [text, setText] = UseState(Texts[0]);
  const [mShipmentTracking, setMShipmentTracking] = UseState(false);
  const [width, setWidth] = UseState(0);

  const _slider = UseRef(0);
  const _sliderButtons = UseRef(0);
  const _sliderDots = UseRef(0);

  const SliderOnload = async () => {
    const sliderItem = _slider.current;
    const sliderDot = _sliderDots.current;

    if (sliderItem) {
      const sliderItems = sliderItem.children;
      const sliderDots = sliderDot.children;

      // Resimleri Gizler.
      for (let i = 0; i < sliderItems.length; i++) {
        if (i == 0) {
          sliderItems[i].classList.add(styles.show);
          sliderDots[i].classList.add(styles.active);
        } else {
          sliderItems[i].classList.add(styles.hidden);
        }
      }
    }
  };

  const SliderByNextAndPrev = async (event) => {
    const sliderItem = _slider.current;
    const sliderDot = _sliderDots.current;

    if (sliderItem) {
      const sliderItems = sliderItem.children;
      const sliderDots = sliderDot.children;

      switch (event) {
        case SliderButton.NEXT:
          index = (index + 1) % sliderItems.length;
          break;
        case SliderButton.PREV:
          index = (index - 1 + sliderItems.length) % sliderItems.length;
          break;
        default:
          index = event;
          break;
      }

      for (let i = 0; i < sliderItems.length; i++) {
        if (i === index) {
          sliderItems[i].classList.remove(styles.hidden);
          sliderItems[i].classList.add(styles.show);

          // Slider Dots
          sliderDots[i].classList.add(styles.active);
        } else {
          sliderItems[i].classList.remove(styles.show);
          sliderItems[i].classList.add(styles.hidden);

          // Slider Dots
          sliderDots[i].classList.remove(styles.active);
        }
      }
    }
  };

  UseEffect(() => {
    (async () => {
      await SliderOnload();

      const SliderInterval = setInterval(() => {
        SliderByNextAndPrev(SliderButton.NEXT);
      }, 5000);

      const buttons = _sliderButtons.current.children;
      for (let i = 1; i < buttons.length; i++) {
        buttons[i].addEventListener("mouseover", () => {
          clearInterval(SliderInterval);
        });

        buttons[i].addEventListener("mouseleave", () => {
          SliderInterval = setInterval(() => {
            SliderByNextAndPrev(SliderButton.NEXT);
          }, 3000);
        });
      }

      const dosts = _sliderDots.current.children;
      for (let i = 1; i < dosts.length; i++) {
        dosts[i].addEventListener("mouseover", () => {
          clearInterval(SliderInterval);
        });

        dosts[i].addEventListener("mouseleave", () => {
          SliderInterval = setInterval(() => {
            SliderByNextAndPrev(SliderButton.NEXT);
          }, 3000);
        });
      }


      setWidth(window.innerWidth);
    })();

    return () => {
      index = 0;
    };
  }, []);

  const GeneralFileFilter = (files) => {
    var generalFile = files[0];
    if (generalFile) {
      var mimeType = GetMimeType(generalFile.name);
      return `${mimeType}${generalFile.file}`;
    }
  };

  //Text Slide Tablet Button
  // UseEffect(() => {
  //   const interval = setInterval(() => {
  //     const nextIndex = (Texts.indexOf(text) + 1) % Texts.length;

  //     setText(Texts[nextIndex]);
  //   }, 2000);
  //   // Temizleme fonksiyonu
  //   return () => clearInterval(interval);
  // }, [text]);

  return (
    <section
      id="slider"
      className={styles.slider}
      style={{
        height: !IsNullOrEmpty(height) && height,
      }}
    >
      {panel && (
        <ShipmentTracking
          mShipmentTrackingOpenOrClosed={mShipmentTracking}
        ></ShipmentTracking>
      )}

      {/* Start List */}
      <ul id="list" ref={_slider} className={styles.list}>
        {sliders.sliderDetails &&
          sliders.sliderDetails.map((data, index) => (
            <li key={index}>
              <Link href={data.sliderDetailUrls && data.sliderDetailUrls.filter(x => x.languageId == Language.LanguageId[lang.toUpperCase()]).length > 0 ? 
              data.sliderDetailUrls.filter(x => x.languageId == Language.LanguageId[lang.toUpperCase()])[0].url : "/#/"}> 
                <a target={data.sliderDetailUrls && data.sliderDetailUrls.filter(x => x.languageId == Language.LanguageId[lang.toUpperCase()]).length > 0 ? "_blank" : ""}>
                  {data.isTextShown && (
                    <div className={styles.title}>
                      <h1>
                        {
                          GetLanguageResource(
                            data.languageResources,
                            LanguageValueType.TITLE
                          ).value
                        }
                      </h1>
                      <h2>
                        {
                          GetLanguageResource(
                            data.languageResources,
                            LanguageValueType.CONTENT
                          ).value
                        }
                      </h2>
                    </div>
                  )}

                  {!IsNullOrEmpty(GeneralFileFilter(data.generalFiles)) && (
                    <div className={styles.image}>
                      <Image
                        src={`${CDN_URL}${
                          data.generalFiles.filter(
                            (x) => x.fileType == FileType.ContentImage
                          )[0].uid
                        }*${
                          data.generalFiles.filter(
                            (x) => x.fileType == FileType.ContentImage
                          )[0].name
                        }`}
                        layout={"fill"}
                      />
                    </div>
                  )}
                </a>
              </Link>
            </li>
          ))}
      </ul>
      {/* End List */}

      {/* Start Button */}
      <ul id="buttons" ref={_sliderButtons} className={styles.buttons}>
        {!butons && width <= 1280 ? (
          <li style={{ display: "none" }}></li>
        ) : (
          <li
            onClick={() => {
              setMShipmentTracking(!mShipmentTracking);
            }}
          >
            <i id={styles.icontruck} className="fa-solid fa-truck-fast"></i>
            <span className={styles.buttonslide}>Gönderi</span>
          </li>
        )}

        <li
          className={mShipmentTracking ? styles.buttonremove : ""}
          onClick={() => {
            SliderByNextAndPrev(SliderButton.NEXT);
          }}
        >
          <i className="fa-solid fa-chevron-right"></i>
        </li>
        <li
          className={mShipmentTracking ? styles.buttonremove : ""}
          onClick={() => {
            SliderByNextAndPrev(SliderButton.PREV);
          }}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </li>
      </ul>
      {/* End Button */}

      {/* Start Dots */}
      <ul id="dots" ref={_sliderDots} className={styles.dots}>
        {sliders.sliderDetails &&
          sliders.sliderDetails.map((data, index) => (
            <li
              key={data.uid}
              onClick={() => {
                SliderByNextAndPrev(index);
              }}
            ></li>
          ))}
      </ul>
      {/* End Dots */}
    </section>
  );
};

export default Slider;
